<template>
<div>
    <header class="page-header">
        <h2>Page Header</h2>
    </header>
    <div class="styleguide">
        <section class="content">
            <h1>Header One</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <h2>Header Two</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <h3>Header Three</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <h4>Header Four</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        </section>
        <section class="content">
            <h2>Color wheels on white</h2>
            <div class="colors white">
                <div v-for="i in 10" :key="i" :class="'brand-' + i"></div>
                <span>Brands</span>
            </div>
            <div class="colors white">
                <div v-for="i in 10" :key="i" :class="'gray-' + i"></div>
                <span>Grays</span>
            </div>
            <div class="colors white">
                <div v-for="i in 10" :key="i" :class="'blue-' + i"></div>
                <span>Blues</span>
            </div>
            <div class="colors white">
                <div v-for="i in 10" :key="i" :class="'green-' + i"></div>
                <span>Greens</span>
            </div>
            <div class="colors white">
                <div v-for="i in 10" :key="i" :class="'red-' + i"></div>
                <span>Reds</span>
            </div>
            <div class="colors white">
                <div v-for="i in 10" :key="i" :class="'yellow-' + i"></div>
                <span>Yellows</span>
            </div>
            <div class="colors white">
                <div v-for="i in 10" :key="i" :class="'purple-' + i"></div>
                <span>Purples</span>
            </div>
            <div class="colors white">
                <div v-for="i in 10" :key="i" :class="'orange-' + i"></div>
                <span>Oranges</span>
            </div>
            <div class="colors white">
                <div v-for="i in 10" :key="i" :class="'maroon-' + i"></div>
                <span>Maroons</span>
            </div>

            
            <h2>Color wheels on black</h2>
            <div class="colors black">
                <div v-for="i in 10" :key="i" :class="'brand-' + i"></div>
                <span>Brands</span>
            </div>
            <div class="colors black">
                <div v-for="i in 10" :key="i" :class="'gray-' + i"></div>
                <span>Grays</span>
            </div>
            <div class="colors black">
                <div v-for="i in 10" :key="i" :class="'blue-' + i"></div>
                <span>Blues</span>
            </div>
            <div class="colors black">
                <div v-for="i in 10" :key="i" :class="'green-' + i"></div>
                <span>Greens</span>
            </div>
            <div class="colors black">
                <div v-for="i in 10" :key="i" :class="'red-' + i"></div>
                <span>Reds</span>
            </div>
            <div class="colors black">
                <div v-for="i in 10" :key="i" :class="'yellow-' + i"></div>
                <span>Yellows</span>
            </div>
            <div class="colors black">
                <div v-for="i in 10" :key="i" :class="'purple-' + i"></div>
                <span>Purples</span>
            </div>
            <div class="colors black">
                <div v-for="i in 10" :key="i" :class="'orange-' + i"></div>
                <span>Oranges</span>
            </div>
            <div class="colors black">
                <div v-for="i in 10" :key="i" :class="'maroon-' + i"></div>
                <span>Maroons</span>
            </div>

            <h2>Color wheels on their root (brand-standard) color</h2>        
            <div class="colors brand">
                <div v-for="i in 10" :key="i" :class="'brand-' + i"></div>
                <span>Brands</span>
            </div>
            <div class="colors gray">
                <div v-for="i in 10" :key="i" :class="'gray-' + i"></div>
                <span>Grays</span>
            </div>
            <div class="colors blue">
                <div v-for="i in 10" :key="i" :class="'blue-' + i"></div>
                <span>Blues</span>
            </div>
            <div class="colors green">
                <div v-for="i in 10" :key="i" :class="'green-' + i"></div>
                <span>Greens</span>
            </div>
            <div class="colors red">
                <div v-for="i in 10" :key="i" :class="'red-' + i"></div>
                <span>Reds</span>
            </div>
            <div class="colors yellow">
                <div v-for="i in 10" :key="i" :class="'yellow-' + i"></div>
                <span>Yellows</span>
            </div>
            <div class="colors purple">
                <div v-for="i in 10" :key="i" :class="'purple-' + i"></div>
                <span>Purples</span>
            </div>
            <div class="colors orange">
                <div v-for="i in 10" :key="i" :class="'orange-' + i"></div>
                <span>Oranges</span>
            </div>
            <div class="colors maroon">
                <div v-for="i in 10" :key="i" :class="'maroon-' + i"></div>
                <span>Maroons</span>
            </div>
        </section>
        <section class="content">
            <h2>Form controls</h2>
            <div>
                <div class="form-group">
                    <label for="exampleInputEmail1">Text input</label>
                    <input type="text" class="form-control" id="text">
                    <span class="form-error"></span>
                </div>
                <div class="form-group">
                    <label for="exampleInputEmail1">Number input</label>
                    <input type="number" class="form-control" id="number">
                    <span class="form-error"></span>
                </div>
                <div class="form-group">
                    <label for="exampleInputEmail1">Dropdown</label>
                    <select class="form-control" id="dropdown">
                        <option>Test 1</option>
                        <option>Test 2</option>
                        <option>Test 3</option>
                    </select>
                    <span class="form-error"></span>
                </div>

            </div>
        </section>
        <section class="content">
            <h2>Equipment Cards</h2>
            <div class="filter">
                <div class="search">

                </div>
                <div class="options">
                    <div class="group">
                        <ul>
                            <li>
                                <div class="form-group">
                                    <input type="checkbox" id="inactive" name="inactive" /><label for="inactive">Show inactive</label>
                                </div>
                            </li>
                        </ul>
                        <h4>
                            Suspension
                        </h4>
                        <ul>
                            <li>
                                <div class="form-group">
                                    <input type="checkbox" id="suspension-air" name="suspension-air" /><label for="suspension-air">Air</label>
                                </div>
                            </li>
                            <li>
                                <div class="form-group">
                                    <input type="checkbox" id="suspension-spring" name="suspension-spring" /><label for="suspension-spring">Spring</label>
                                </div>
                            </li>
                            <li>
                                <div class="form-group">
                                    <input type="checkbox" id="suspension-unknown" name="suspension-unknown" /><label for="suspension-unknown">Unknown</label>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
            <div class="cards">
                <a class="card" href="#">
                    <h3 class="title">TS13033</h3>
                    <div class="icons">
                        <i class="fas fa-wind suspension air"></i>
                        <i class="fas door swing"></i>
                        <i class="fas fa-map-marker-alt gps success"></i>
                    </div>
                    <div class="description">2008 WABASH 53' VAN</div>
                </a>
                <a class="card" href="#">
                    <h3 class="title">TR949</h3>
                    <div class="icons">
                        <i class="fas fa-wind suspension air"></i>
                        <i class="fas door swing"></i>
                        <i class="fas fa-map-marker-alt gps success"></i>
                    </div>
                    <div class="description">2014 East MAINT CONTRACT-FGN U</div>
                </a>
                <a class="card" href="#">
                    <h3 class="title">TS13033</h3>
                    <div class="icons">
                        <i class="fas fa-wind suspension air"></i>
                        <i class="fas door none"></i>
                        <i class="fas fa-map-marker-alt gps success"></i>
                    </div>
                    <div class="description">2008 WABASH 53' VAN</div>
                </a>
                <a class="card" href="#">
                    <h3 class="title">TS13033</h3>
                    <div class="icons">
                        <i class="fas fa-wind suspension air"></i>
                        <i class="fas door swing"></i>
                        <i class="fas fa-map-marker-alt gps danger"></i>
                    </div>
                    <div class="description">2008 WABASH 53' VAN</div>
                </a>
                <a class="card" href="#">
                    <h3 class="title">TS13033</h3>
                    <div class="icons">
                        <i class="fas fa-wind suspension air"></i>
                        <i class="fas door swing"></i>
                        <i class="fas fa-map-marker-alt gps warning"></i>
                    </div>
                    <div class="description">2008 WABASH 53' VAN</div>
                </a>
                <a class="card" href="#">
                    <h3 class="title">TS13033</h3>
                    <div class="icons">
                        <i class="fas fa-wind suspension air"></i>
                        <i class="fas door swing"></i>
                        <i class="fas fa-map-marker-alt gps success"></i>
                    </div>
                    <div class="description">2008 WABASH 53' VAN</div>
                </a>
                <sbs-feed></sbs-feed>
            </div>
        </section>
    </div>
</div>
</template>

<script>

    export default {
        name: 'StyleGuide'
    }
</script>

<style lang="scss">
@import "../assets/scss/variables.scss";
.styleguide{
    margin: 0 auto;

    .content {
        margin: 0 0 $space-wide;
    }

    .colors{
        display: flex;
        justify-content: space-between;
        padding: $space-wide $space-wide;
        margin: 0 0 - $space-standard; 
        position: relative;

        >div{
            height: 30px;
            width: 30px;
        }
        >span{
            position: absolute;
            top: 0;
            left: $space-wide;
            right: $space-wide;
            text-align: center;
            color: $white;
        }
        + h2{
            margin-top: $space-wide;
        }

    }
    .white{
        >span{
            color: #000;
        }
    }
    .black{
        background: #000;
    }

    .brand {
        background: $brand;
    }
    .gray {
        background: $gray;
    }
    .blue {
        background: $blue;
    }
    .green {
        background: $green;
    }
    .yellow {
        background: $yellow;
    }
    .purple {
        background: $purple;
    }
    .orange {
        background: $orange;
    }
    .red {
        background: $red;
    }
    .maroon {
        background: $maroon;
    }


    @for $i from 1 through 10{
        .blue-#{$i} {
            background: nth($blues, $i);
        }
        .gray-#{$i} {
            background: nth($grays, $i);
        }
        .green-#{$i} {
            background: nth($greens, $i);
        }
        .brand-#{$i} {
            background: nth($brands, $i);
        }
        .yellow-#{$i} {
            background: nth($yellows, $i);
        }
        .purple-#{$i} {
            background: nth($purples, $i);
        }
        .orange-#{$i} {
            background: nth($oranges, $i);
        }
        .red-#{$i} {
            background: nth($reds, $i);
        }
        .maroon-#{$i} {
            background: nth($maroons, $i);
        }
    }
}
</style>